import React from 'react';
import './Q1.scss';
import CalendarBullets from '../shared/CalendarBullets.jsx'
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
import QuestionNav from '../shared/QuestionNav.jsx'
const figure = require('./Q1_figure.png')

const Q1 = ({ lexicon, studyId }) => {

  return (
    <div id="Q1">
      <div className="top">
        <div className="left">
          <div className="navs">
            <MainNav current="/qanda" studyId={studyId} />
            <QuestionNav lexicon={lexicon} current="q1" studyId={studyId} />
          </div>
          <div className="headers">
            <h1>{lexicon.get("Q1.title")}</h1>
          </div>
        </div>
        <div className="right desktop">
          <img src={figure} alt="Pregnant pic"/>
        </div>
      </div>
      <div className="bottom">
        <div className="calendars">
          <CalendarBullets 
            title={lexicon.get("Q1.wait")}
            bullets={[lexicon.get("Q1.keep-going"),
                      lexicon.get("Q1.labor-starts"),   
                      lexicon.get("Q1.complication"),
                      lexicon.get("Q1.if-week")    
                    ]} 
            calendarType="wait"
          />
          <CalendarBullets 
            title={lexicon.get("Q1.schedule")}
            bullets={[lexicon.get("Q1.schedule-day"),
                      lexicon.get("Q1.39-weeks"),   
                      lexicon.get("Q1.if-problem")    
                    ]} 
            calendarType="schedule"
          />
          <div className="mobile">
            <BackNext backPath="/about" nextPath="/q2" studyId={studyId}/>
          </div>
        </div>
        <div className="back-next-desktop">
          <BackNext backPath="/about" nextPath="/q2" studyId={studyId}/>
        </div>
      </div>
    </div>
  );
}

export default Q1 ;
