import React from 'react';
import './Summary.scss';
const saveButton = require('./Save.png');
const mom = require('../shared/Definitely.png')

const Summary = ({ answer1, answer2, answer3, answer4, answer5, answer6, lexicon, studyId }) => {

  const welcomeUrl = "https://" + window.location.hostname + "/welcome/" + studyId

  return (
    <div id="Summary">
      <div className="top">
        <h1>{lexicon.get("title")}</h1>
        <p>{lexicon.get("subtitle-1")}</p>
      </div>
      <div className="answers">
        <h3>{lexicon.get("subtitle-2")}</h3>
        <div className="q1">
          <p className="question">{lexicon.get("q1")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer1 || ""}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer1 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer1 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer1 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer1 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer1 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
        <div className="q2">
          <p className="question">{lexicon.get("q2")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer2 === null ? "" : answer2}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer2 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer2 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer2 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer2 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer2 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
        <div className="q3">
          <p className="question">{lexicon.get("q3")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer3 || ""}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer3 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer3 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer3 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer3 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer3 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
        <div className="q4">
          <p className="question">{lexicon.get("q4")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer4 || ""}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer4 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer4 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer4 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer4 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer4 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
        <div className="q5">
          <p className="question">{lexicon.get("q5")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer5 || ""}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer5 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer5 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer5 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer5 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer5 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
        <div className="q6">
          <p className="question">{lexicon.get("q6")}</p>
          <span className="mobile prompt">{lexicon.get("your-answer")}</span>
          <span className="mobile answer">{answer6 || ""}</span>
          <div className="desktop answer">
            <span className={"matrix " + (answer6 === "Definitely")}>{lexicon.get("definitely")}</span>
            <span className={"matrix " + (answer6 === "Probably")}>{lexicon.get("probably")}</span>
            <span className={"matrix " + (answer6 === "Doesn't matter")}>{lexicon.get("doesnt-matter")}</span>
            <span className={"matrix " + (answer6 === "Probably not")}>{lexicon.get("probably-not")}</span>
            <span className={"matrix " + (answer6 === "Definitely not")}>{lexicon.get("definitely-not")}</span>
          </div>
        </div>
      </div>
      <div className="desktop notes">
        <span>{lexicon.get("tool")}</span>
        <span className="url">{welcomeUrl}</span>
      </div>
      <div className="best-wishes">
        <h2>{lexicon.get("best-wishes")}</h2>
        <img className="desktop" src={mom} alt="Happy mom"></img>
      </div>
      <div className="bottom">
        <button>
          <img onClick={() => alert("Will implement soon!!")} className="save" src={saveButton} alt="save button" />
        </button>
      </div>

    </div>
  );
}

export default Summary ;
