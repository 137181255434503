import React from 'react';
import './Stories.scss';
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
import Markdown from '../Markdown'
const elWoman = require('./ElWoman.png')
const womanOnCHair = require('./WomanOnChair.png')

const Stories = ({ lexicon, studyId }) => {

  return (
    <div id="Stories">
      <div className="top">
        <MainNav current="/stories" studyId={studyId} />
        <div className="headers">
          <h1>{lexicon.get("title")}</h1>
          <p>{lexicon.get("subtitle-1")}</p>
          <p>{lexicon.get("subtitle-2")}</p>
        </div>
      </div>
      <div className="sections">
        <div className="wait section">
          <h3 className="section-header">{lexicon.get("why-wait")}</h3>
          <div className="side-by-side">
            <img src={womanOnCHair} alt="Woman on chair"></img>
            <div className="stories">
              <div className="story">
                <p>{lexicon.get("story-1")}</p>
              </div>
              <div className="story">
                <p>{lexicon.get("story-2")}</p>
              </div>
            </div>
          </div>
          <div className="story less-margin">
            <p>{lexicon.get("story-3")}</p>
          </div>
          <div className="story">
            <p>{lexicon.get("story-4")}</p>
          </div>
        </div>
        <div className="schedule section">
          <h3 className="section-header">{lexicon.get("why-schedule")}</h3>
          <div className="side-by-side">
            <img className="el-woman" src={elWoman} alt="Woman with travel bag"></img>
            <div className="stories">
              <div className="story">
                <p>{lexicon.get("story-5")}</p>
              </div>
              <div className="story">
                <p>{lexicon.get("story-6")}</p>
              </div>
            </div>
          </div>
          <div className="story">
            <p>{lexicon.get("story-7")}</p>
          </div>
          <div className="story">
            <p>{lexicon.get("story-8")}</p>
          </div>
        </div>
      </div>
      <BackNext backPath="/highbp" nextPath="/values-start" studyId={studyId} />
    </div>
  );
}

export default Stories ;
