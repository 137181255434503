import React from 'react';
import './BackNext.scss';
import { Link } from 'react-router-dom';
const backButton = require('../shared/Back.png');
const nextButton = require('../shared/Next.png');

const BackNext = ({ backPath, nextPath, otherNextButton, nextClass, studyId }) => {

  let safeBackPath = backPath + "/" + studyId
  if (backPath === "/") {
    safeBackPath = "/"
  }
  return (
    <div id="BackNext">
      <Link to={safeBackPath}>
        <img className="back" src={backButton} alt="back button" />
      </Link>
      <Link to={nextPath + "/" + studyId}>
        <img className={nextClass || "next"} src={otherNextButton || nextButton} alt="back button" />
      </Link>
    </div>
  );
}

export default BackNext ;
