import React from 'react';
import './ValuesAnswers.scss';

const definitelyMom = require('../shared/Definitely.png');
const probablyMom = require('../shared/Probably.png');
const doesntMatterMom = require('../shared/DoesntMatter.png');
const probablyNotMom = require('../shared/ProbNot.png');
const definitelyNotMom = require('../shared/DefNot.png');

const DEFINITELY = "Definitely"
const PROBABLY = "Probably"
const DOESNT_MATTER= "Doesn't matter"
const PROBABLY_NOT = "Probably not"
const DEFINITELY_NOT = "Definitely not"

const ValuesAnswers = ({ answer, 
                         setAnswer, 
                         definitelyPic,
                         probablyPic,
                         doesntMatterPic,
                         probablyNotPic,
                         definitelyNotPic,
                         definitelyDesktopPic,
                         probablyDesktopPic,
                         doesntMatterDesktopPic,
                         probablyNotDesktopPic,
                         definitelyNotDesktopPic,
                         question,
                         lexicon 
                      }) => {

  return (
    <div id="ValuesAnswers">
      <div className="mom-and-answers">
        <p className="desktop select">{lexicon.get("select")}</p>
        <div className="answers">
          <p className="mobile select">{lexicon.get("select")}</p>
          <div className="definitely">
            { 
              answer === DEFINITELY
              ?
              <div className="placeholder">
                <img className="mobile" src={definitelyPic} alt="Definitely"></img>
                <img className="desktop" src={definitelyDesktopPic} alt="Definitely"></img>
              </div>
              :
              <button className={question} onClick={() => setAnswer(DEFINITELY)}>
                <p>{lexicon.get("definitely")}</p>
              </button>
            }
          </div>
          <div className="probably">
            { 
              answer === PROBABLY
              ?
              <div className="placeholder">
                <img className="mobile" src={probablyPic} alt="Probably"></img>
                <img className="desktop" src={probablyDesktopPic} alt="Probably"></img>
              </div>
              :
              <button className={question} onClick={() => setAnswer(PROBABLY)}>
                <p>{lexicon.get("probably")}</p>
              </button>
            }
          </div>
          <div className="doesnt-matter">
            { 
              answer === DOESNT_MATTER
              ?
              <div className="placeholder">
                <img className="mobile" src={doesntMatterPic} alt="Doesn't Matter"></img>
                <img className="desktop" src={doesntMatterDesktopPic} alt="Doesn't Matter"></img>
              </div>
              :
              <button className={question} onClick={() => setAnswer(DOESNT_MATTER)}>
                <p>{lexicon.get("doesnt-matter")}</p>
              </button>
            }
          </div>
          <div className="probably-not">
            { 
              answer === PROBABLY_NOT
              ?
              <div className="placeholder">
                <img className="mobile" src={probablyNotPic} alt="Probably Not"></img>
                <img className="desktop" src={probablyNotDesktopPic} alt="Probably Not"></img>
              </div>
              :
              <button className={question} onClick={() => setAnswer(PROBABLY_NOT)}>
                <p>{lexicon.get("probably-not")}</p>
              </button>
            }
          </div>
          <div className="definitely-not">
            { 
              answer === DEFINITELY_NOT
              ?
              <div className="placeholder">
                <img className="mobile" src={definitelyNotPic} alt="Definitely Not"></img>
                <img className="desktop" src={definitelyNotDesktopPic} alt="Definitely Not"></img>
              </div>
              :
              <button className={question} onClick={() => setAnswer(DEFINITELY_NOT)}>
                <p>{lexicon.get("definitely-not")}</p>
              </button>
            }
          </div>
        </div>
        <div className="mom-container">
          {
            answer === DEFINITELY && 
            <img className="mom definitely" src={definitelyMom} alt="Mom Pic"></img>
          }
          {
            answer === PROBABLY && 
            <img className="mom probably" src={probablyMom} alt="Mom Pic"></img>
          }
          {
            answer === DOESNT_MATTER && 
            <img className="mom doesnt-matter" src={doesntMatterMom} alt="Mom Pic"></img>
          }
          {
            answer === PROBABLY_NOT &&
            <img className="mom probably-not" src={probablyNotMom} alt="Mom Pic"></img>
          }
          {
            answer === DEFINITELY_NOT && 
            <img className="mom definitely-not" src={definitelyNotMom} alt="Mom Pic"></img>
          }
        </div>
      </div>
    </div>
  );
}

export default ValuesAnswers ;
