import React from 'react';
import './ProsCons.scss';
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
import Markdown from '../Markdown'
const nextButton = require('./ChancesCsection.png')
const elWoman = require('./ElWoman.png')
const womanOnChair = require('./WomanOnChair.png')

const ProsCons = ({ lexicon, studyId }) => {

  return (
    <div id="ProsCons">
      <div className="top">
        <MainNav current="/summary" studyId={studyId} />
        <div className="headers">
          <h2>{lexicon.get("title-1")}</h2>
          <h1>{lexicon.get("title-2")}</h1>
          <h2 className="last">{lexicon.get("title-3")}</h2>
        </div>
      </div>
      <div className="sections">
      <div className="wait section">
        <h3 className="section-header">{lexicon.get("wait")}</h3>
        <div className="block">

        <img className="wait-woman" src={womanOnChair} alt="Woman on chair"></img>
        <div className="pros">
          <div className="pros-list">
            <h3>{lexicon.get("pros")}</h3>
            <ul>
              <li>{lexicon.get("you-may")}</li>
              <li>{lexicon.get("shorter-labor")}</li>
              <li>{lexicon.get("fewer-medications")}</li>
            </ul>
          </div>
        </div>
        </div>
        <div className="cons">
          <h3>{lexicon.get("cons")}</h3>
          <ul>
            <li>
              <Markdown str={lexicon.get("complication")} />
            </li>
            <li>{lexicon.get("induction")}</li>
          </ul>
        </div>
      </div>
      <div className="schedule section">
        <h3 className="section-header">{lexicon.get("schedule")}</h3>
        <img className="el-woman" src={elWoman} alt="Woman with travel bag"></img>
        <div className="pros">
          <div className="pros-list">
            <h3>{lexicon.get("pros")}</h3>
            <ul>
              <li><Markdown str={lexicon.get("choose")} /></li>
              <li><Markdown str={lexicon.get("lower-risk")} /></li>
              <li><Markdown str={lexicon.get("c-section")} /></li>
            </ul>
          </div>
          <div className="cons-list">
            <h3>{lexicon.get("cons")}</h3>
            <ul>
              <li>{lexicon.get("longer")}</li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      <BackNext backPath="/q6" nextPath="/csection" otherNextButton={nextButton} nextClass="cs" studyId={studyId}/>
    </div>
  );
}

export default ProsCons ;
