import React from 'react';
import './Q5.scss';
import CalendarBullets from '../shared/CalendarBullets.jsx'
import BackNext from '../shared/BackNext.jsx'
import BothCalendars from '../shared/BothCalendars.jsx'
import QuestionNav from '../shared/QuestionNav.jsx'
import MainNav from '../shared/MainNav.jsx'
//const figure = require('./Q5_figure.png')
//const figure = require('../shared/Definitely.png');
const figure = require('./mom_no_heart.png')

const Q5 = ({ lexicon, studyId }) => {

  return (
    <div id="Q5">
      <div className="top">
        <div className="left">
          <div className="navs">
            <MainNav current="/qanda" studyId={studyId} />
            <QuestionNav lexicon={lexicon} current="q5" studyId={studyId} />
          </div>
          <div className="headers">
            <h1>{lexicon.get("Q5.title")}</h1>
          </div>
        </div>
        <div className="right desktop">
          <img src={figure} alt="Pregnant pic"/>
        </div>
      </div>
      <div className="calendars">
        <div className="mobile">
          <div className="calendar-container">
            <CalendarBullets 
              title={lexicon.get("Q5.same")}
              bullets={[lexicon.get("Q5.until"),
                        lexicon.get("Q5.problem"),
                        lexicon.get("Q5.once")
                      ]} 
              calendarType="both"
            />
          </div>
          <BackNext backPath="/q4" nextPath="/q6" studyId={studyId} />
        </div>
        <div className="desktop">
          <div className="bottom">
            <div className="wait">
            </div>
            <div className="schedule">
            </div>
            <div className="content">
              <div className="row">
                <BothCalendars />
                <h3>{lexicon.get("Q5.same")}</h3>
              </div>
              <ul>
                <li>{lexicon.get("Q5.until")}</li>
                <li>{lexicon.get("Q5.problem")}</li>
                <li>{lexicon.get("Q5.once")}</li>
              </ul>
              <div className="back-next-desktop">
                <BackNext backPath="/q4" nextPath="/q6" studyId={studyId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q5 ;
