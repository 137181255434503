import React from 'react';
import './Values.scss';
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
const nextButton = require('../shared/Start.png')

const Values = ({ lexicon, studyId }) => {

  return (
    <div id="Values">
      <div className="top">
        <MainNav current="/stories" studyId={studyId} />
        <h1>{lexicon.get("title")}</h1>
        <p>{lexicon.get("as-long")}</p>
        <p>{lexicon.get("considering-how")}</p>
      </div>
      <div className="bottom">
        <BackNext backPath="/stories" nextPath="/values-q1" otherNextButton={nextButton} studyId={studyId} />
      </div>
    </div>
  );
}

export default Values ;
