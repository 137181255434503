import React from 'react';
import './ValuesQ5.scss';
import ValuesQuestion from '../shared/ValuesQuestion.jsx'
const locator = require('./Locator_4.png')

const definitelyPic = require('./Def_yellow.png')
const probablyPic = require('./Prob_yellow.png')
const doesntMatterPic = require('./DsntMatter_yellow.png')
const probablyNotPic = require('./ProbNot_yellow.png')
const definitelyNotPic = require('./DefNot_yellow.png')

const definitelyDesktopPic = require('./desktop/Def_yellow.png')
const probablyDesktopPic = require('./desktop/Prob_yellow.png')
const doesntMatterDesktopPic = require('./desktop/DsntMatter_yellow.png')
const probablyNotDesktopPic = require('./desktop/ProbNot_yellow.png')
const definitelyNotDesktopPic = require('./desktop/DefNot_yellow.png')

const ValuesQ5 = ({ answer, setAnswer, lexicon, answersLexicon, studyId }) => {

  return (
    <div id="ValuesQ5">
        <ValuesQuestion 
          question="q5"
          answer={answer} 
          setAnswer={setAnswer} 
          lexicon={lexicon} 
          answersLexicon={answersLexicon} 
          definitelyPic={definitelyPic} 
          probablyPic={probablyPic} 
          doesntMatterPic={doesntMatterPic} 
          probablyNotPic={probablyNotPic} 
          definitelyNotPic={definitelyNotPic} 
          definitelyDesktopPic={definitelyDesktopPic} 
          probablyDesktopPic={probablyDesktopPic} 
          doesntMatterDesktopPic={doesntMatterDesktopPic} 
          probablyNotDesktopPic={probablyNotDesktopPic} 
          definitelyNotDesktopPic={definitelyNotDesktopPic} 
          backPath="/values-q4"
          nextPath="/values-q6"
          locator={locator}
          studyId={studyId}
        />
    </div>
  );
}

export default ValuesQ5 ;
