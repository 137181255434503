import React, { useState } from 'react';
import './Q4.scss';
import CalendarBullets from '../shared/CalendarBullets.jsx'
import BackNext from '../shared/BackNext.jsx'
import Popup from '../shared/Popup.jsx'
import MainNav from '../shared/MainNav.jsx'
import QuestionNav from '../shared/QuestionNav.jsx'
import BothCalendars from '../shared/BothCalendars.jsx'
const figure = require('./Q4_figure.png')
const pain1 = require('./Pain1.png')
const pain2 = require('./Pain2.png')
const pain3 = require('./Pain3.png')
const pain4 = require('./Pain4.png')

const Q4 = ({ lexicon, studyId }) => {

  const [isPopupOpen, setPopupOpen] = useState(false);

  return (
    <div id="Q4">
      {isPopupOpen &&
        <Popup 
          setPopupOpen={setPopupOpen} 
          content={
            <div className="popup">
              <h2 className="title">{lexicon.get("Q4.popup.title")}</h2>
                <h4>{lexicon.get("Q4.popup.intro")}</h4>
              <div className="row">
                <img src={pain1} alt="iv" />
                <p>{lexicon.get("Q4.popup.early-iv")}</p>
              </div>
              <div className="row">
                <img src={pain2} alt="nitrous" />
                <p>{lexicon.get("Q4.popup.nitrous")}</p>
              </div>
              <div className="row with-column">
                <img src={pain3} alt="epidural" />
                <div className="column">
                  <p>{lexicon.get("Q4.popup.choose-epidural")}</p>
                  <p>{lexicon.get("Q4.popup.epidural-is")}</p>
                  <p>{lexicon.get("Q4.popup.epidural-medication")}</p>
                  <p>{lexicon.get("Q4.popup.epidural-surgery")}</p>
                  <p>{lexicon.get("Q4.popup.spinal-anesthetic")}</p>
                </div>
              </div>
              <div className="row">
                <img src={pain4} alt="general" />
                <p>{lexicon.get("Q4.popup.general-anesthetic")}</p>
              </div>
            </div>
          } 
        />
      }
      <div className="top">
        <div className="left">
          <div className="navs">
            <MainNav current="/qanda" studyId={studyId} />
            <QuestionNav lexicon={lexicon} current="q4" studyId={studyId} />
          </div>
          <div className="headers">
            <h1>{lexicon.get("Q4.title")}</h1>
          </div>
        </div>
        <div className="right desktop">
          <img src={figure} alt="Pregnant pic"/>
        </div>
      </div>
      <div className="calendars">
        <div className="mobile">
          <div className="calendar-plus">
          <CalendarBullets 
            title={lexicon.get("Q4.same")}
            bullets={[lexicon.get("Q4.can-have"),
                      lexicon.get("Q4.experience")
                    ]} 
            calendarType="both"
          />
          <div className="popup-link">
            <button onClick={() => setPopupOpen(true)}>{lexicon.get("Q4.learn-more")}</button>
          </div>
          </div>
          <BackNext backPath="/q3" nextPath="/q5" studyId={studyId} />
        </div>

        <div className="desktop">
          <div className="bottom">
            <div className="wait">
            </div>
            <div className="schedule">
            </div>
            <div className="content">
              <div className="row">
                <BothCalendars />
                <h3>{lexicon.get("Q4.same")}</h3>
              </div>
              <ul>
                <li>{lexicon.get("Q4.can-have")}</li>
                <li>{lexicon.get("Q4.experience")}</li>
              </ul>
              <div className="popup-link">
                <button onClick={() => setPopupOpen(true)}>{lexicon.get("Q4.learn-more")}</button>
              </div>
              <div className="back-next-desktop">
                <BackNext backPath="/q3" nextPath="/q5" studyId={studyId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q4 ;
