import React from 'react';
import './MainNav.scss';
import { Link } from 'react-router-dom';

const MainNav = ({ current, studyId }) => {

  return (
    <div id="MainNav">
      <Link className={"item" + (current === "/" ? " selected" : "")} to={"/welcome/" + studyId}>
        <span>Welcome</span>
      </Link>
      <Link className={"item" + (current === "/about" ? " selected" : "")} to={"/about/" + studyId}>
        <span>About</span>
      </Link>
      <Link className={"item" + (current === "/qanda" ? " selected" : "")} to={"/q1/"  + studyId}>
        <span>Q &  A</span>
      </Link>
      <Link className={"item" + (current === "/summary" ? " selected" : "")} to={"/proscons/" + studyId}>
        <span>Summary</span>
      </Link>
      <Link className={"item" + (current === "/stories" ? " selected" : "")} to={"/stories/" + studyId}>
        <span>Stories</span>
      </Link>
      <Link className={"item" + (current === "/questionnaire" ? " selected" : "")} to={"/questionnaire/" + studyId}>
        <span>Questionnaire</span>
      </Link>
    </div>
  );
}

export default MainNav ;
