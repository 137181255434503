import React from 'react';
import { Link } from 'react-router-dom';
import './About.scss';
import Markdown from '../Markdown'
const nextButton = require('./Start.png')

const About = ({ lexicon, studyId }) => {

  return (
    <div id="About">
      <div className="top-page">
        <div className="sections">
          <div className="intro-and-content">
            <div className="intro">
              <h1>{lexicon.get("title")}</h1>
              <div className="time">
                <h2>{lexicon.get("time")}</h2>
              </div>
            </div>
            <div className="bullet-content">
              <ul>
                <li className="first">
                  <div className="bullet-text">
                    <Markdown str={lexicon.get("first")} />
                  </div>
                </li>
                <li className="then">
                  <div className="bullet-text">
                    <Markdown str={lexicon.get("then")} />
                  </div>
                </li>
                <li className="end">
                  <div className="bullet-text">
                    <Markdown str={lexicon.get("end")} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="help">
            <h1>{lexicon.get("help")}</h1>
            <div className="help-text">
              <Markdown str={lexicon.get("goal")} />
            </div>
          </div>
          <div className="mobile start">
            <h2>{lexicon.get("begin")}</h2>
            <Link to={"/q1/" + studyId}> 
              <img src={nextButton} alt="Start Button"></img>
            </Link> 
          </div>
        </div>
      </div>
      <div className="bottom-page desktop">
        <div className="time">
          <h2>{lexicon.get("six-questions")}</h2>
        </div>
        <div className="questions">
          <div className="row">
            <Link to={"/q1/" + studyId}> 
              <button className="when">
                <p>{lexicon.get("when-hospital")}</p>
              </button>
            </Link>
            <Link to={"/q2/" + studyId}> 
              <button className="labor"> 
                <p>{lexicon.get("what-happens")}</p>
              </button>
            </Link>
            <Link to={"/q3/" + studyId}> 
              <button className="water">
                  <div className="bullet-text">
                    <Markdown str={lexicon.get("when-water-breaks")} />
                  </div>
              </button>
            </Link>
            <Link to={"/q4/" + studyId}> 
              <button className="pain"> 
                <p>{lexicon.get("what-if-pain")}</p>
              </button>
            </Link>
            <Link to={"/q5/" + studyId}> 
              <button className="safe">
                <p>{lexicon.get("how-safe")}</p>
              </button>
            </Link>
            <Link to={"/q6/" + studyId}> 
              <button className="c-section"> 
                <p>{lexicon.get("c-section")}</p>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About ;
