import React, { useEffect } from 'react';
import './Background.scss';

const Background = ({ children }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  });


  return (
    <div id="Background">
      <div className="child-content">
        {children}
      </div>
    </div>
  );
}

export default Background ;
