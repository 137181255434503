import React from 'react';
import './BothCalendars.scss';
const elCalendar = require('../shared/both_el_calendar.png');
const waitCalendar = require('../shared//both_wait_calendar.png');

const BothCalendars = () => {

  return (
    <div id="BothCalendars">
      <img id="wait" src={waitCalendar} alt="Wait calendar pic" />
      <img id="schedule" src={elCalendar} alt="Schedule calendar pic" />
    </div>
  );
}

export default BothCalendars ;
