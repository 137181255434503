import React from 'react';
import { Link } from 'react-router-dom';
import './Study.scss';
const nextButton = require('../shared/Next.png')

const Study = ({ lexicon, studyId, setStudyId }) => {

  return (
    <div id="Study">
      <h1>{lexicon.get("begin")}</h1>
      <div className="input">
        <label>
          <p>{lexicon.get("study-id")}</p>
          <input type="text" value={studyId} onChange={(e) => setStudyId(e.target.value)} />
        </label>
      </div>
      <div className="next">
        <Link to={"/welcome/" + studyId}> 
          <img src={nextButton} className="next-button" alt="Next Button" />
        </Link>
      </div>
    </div>
  );
}

export default Study ;
