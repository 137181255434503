import React, { useState } from 'react';
import './HighBP.scss';
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
const mom = require('./WhiteMom.png')
const greenHeart = require('./greenHeart.png')
const purpleHeart = require('./purpleHeart.png')
const rateHeart = require('./rateHeart.png')

const HighBP = ({ lexicon, studyId }) => {

  const [isWaitResultsOpen, setWaitResultsOpen] = useState(false);
  const [show14Text, setShow14Text] = useState(false);
  const [show9Text, setShow9Text] = useState(false);
  const [isScheduledResultsOpen, setScheduledResultsOpen] = useState(false);

  const wallRow = ((num, src=mom, className="mom", trigger) => {
    return [...Array(num)].map((_, i) => {
      return(
          <img className={`${className} ${trigger === true ? "fade" :""}`} key={i} src={src} alt="heart pic"/>
      )
    });
  });

  const waitWall = (() => {
    return (
      <div className="walls">
        <div className="mom-wall wall">
            {wallRow(100, mom, "mom", isWaitResultsOpen)}
        </div>
        <div className="heart-wall wall">
            {wallRow(86, greenHeart, "normal heart", isWaitResultsOpen)}
            {wallRow(14, rateHeart, "highbp heart", isWaitResultsOpen)}
        </div>
      </div>
    )
  });

  const scheduleWall = (() => {
    return (
      <div className="walls">
        <div className="mom-wall wall">
            {wallRow(100, mom, "mom", isScheduledResultsOpen)}
        </div>
        <div className="heart-wall wall">
            {wallRow(91, purpleHeart, "normal heart", isScheduledResultsOpen)}
            {wallRow(9, rateHeart, "highbp heart", isScheduledResultsOpen)}
        </div>
      </div>
    )
  });

  const showResults = ((setterFunction, delay, delayedSetterFunction) => {
    setterFunction(true);
    setTimeout(() => delayedSetterFunction(true), delay);
  })

  return (
    <div id="HighBP">
      <div className="top">
        <MainNav current="/summary" studyId={studyId}/>
        <div className="headers">
          <div className="titles">
            <h2>{lexicon.get("title-1")}</h2>
            <h1>{lexicon.get("title-2")}</h1>
          </div>
          <p>{lexicon.get("subtitle-1")}</p>
          <p>{lexicon.get("subtitle-2")}</p>
        </div>
      </div>
      <div className="sections">
        <div className="wait section">
          <div className="section-header">
            <div className="hundred-text">
              <p className="hundred">{lexicon.get("100-waiters-1")}</p>
              <p className="hundred">{lexicon.get("100-waiters-2")}</p>
            </div>
            {isWaitResultsOpen 
              ?
              <div className="result-text">
                <div className="text-row">
                  <img className="normal" src={greenHeart} alt="heart pic"/>
                  <p>{lexicon.get("86")}</p>
                </div>
                { show14Text &&
                  <div className="text-row">
                    <img className="highbp" src={rateHeart} alt="heart pic"/>
                    <p>{lexicon.get("14")}</p>
                  </div>
                }
              </div>
              :
              <button className="show-results" onClick={() => showResults(setWaitResultsOpen, 1000, setShow14Text)}>{lexicon.get("show-results")}</button>
            }
            </div>
          { waitWall() }
        </div>
        <div className="schedule section">
          <div className="section-header">
            <div className="hundred-text">
              <p className="hundred">{lexicon.get("100-schedulers-1")}</p>
              <p className="hundred">{lexicon.get("100-schedulers-2")}</p>
            </div>
            {isScheduledResultsOpen 
              ?
              <div className="result-text">
                <div className="text-row">
                  <img className="normal" src={purpleHeart} alt="heart pic"/>
                  <p>{lexicon.get("91")}</p>
                </div>
                { show9Text &&
                  <div className="text-row">
                    <img className="highbp" src={rateHeart} alt="heart pic"/>
                    <p>{lexicon.get("9")}</p>
                  </div>
                }
              </div>
              :
              <button className="show-results" onClick={() => showResults(setScheduledResultsOpen, 1000, setShow9Text)}>{lexicon.get("show-results")}</button>
            }
            </div>
          { scheduleWall() }
        </div>
      </div>
      <BackNext backPath="/csection" nextPath="/stories" studyId={studyId} />
    </div>
  );
}

export default HighBP ;
