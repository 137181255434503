import React, { useState } from 'react';
import './CSection.scss';
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
const nextButton = require('./ChancesHBP.png')
const mom = require('./WhiteMom.png')
const waitVaginalBaby = require('./GreenV.png')
const cSectionBaby = require('./GrayC.png')

const CSection = ({ lexicon, studyId }) => {

  const [isWaitResultsOpen, setWaitResultsOpen] = useState(false);
  const [show24Text, setShow24Text] = useState(false);
  const [show20Text, setShow20Text] = useState(false);
  const [isScheduledResultsOpen, setScheduledResultsOpen] = useState(false);

  const wallRow = ((num, src=mom, className="mom", trigger) => {
    return [...Array(num)].map((_, i) => {
      return(
          <img className={`${className} ${trigger === true ? "fade" :""}`} key={i} src={src} alt="fetus pic"/>
      )
    });
  });

  const waitWall = (() => {
    return (
      <div className="walls">
        <div className="mom-wall wall">
            {wallRow(100, mom, "mom", isWaitResultsOpen)}
        </div>
        <div className="fetus-wall wall">
            {wallRow(76, waitVaginalBaby, "vaginal fetus", isWaitResultsOpen)}
            {wallRow(24, cSectionBaby, "c-section fetus", isWaitResultsOpen)}
        </div>
      </div>
    )
  });

  const scheduleWall = (() => {
    return (
      <div className="walls">
        <div className="mom-wall wall">
            {wallRow(100, mom, "mom", isScheduledResultsOpen)}
        </div>
        <div className="fetus-wall wall">
            {wallRow(80, waitVaginalBaby, "vaginal fetus", isScheduledResultsOpen)}
            {wallRow(20, cSectionBaby, "c-section fetus", isScheduledResultsOpen)}
        </div>
      </div>
    )
  });

  const showResults = ((setterFunction, delay, delayedSetterFunction) => {
    setterFunction(true);
    setTimeout(() => delayedSetterFunction(true), delay);
  })

  return (
    <div id="CSection">
      <div className="top">
        <MainNav current="/summary" studyId={studyId}/>
        <div className="headers">
          <div className="titles">
            <h2>{lexicon.get("title-1")}</h2>
            <h1>{lexicon.get("title-2")}</h1>
          </div>
          <p>{lexicon.get("subtitle-1")}</p>
          <p>{lexicon.get("subtitle-2")}</p>
        </div>
      </div>
      <div className="sections">
        <div className="wait section">
          <div className="section-header">
            <div className="hundred-text">
              <p className="hundred">{lexicon.get("100-waiters-1")}</p>
              <p className="hundred">{lexicon.get("100-waiters-2")}</p>
            </div>
            {isWaitResultsOpen 
              ?
              <div className="result-text">
                <div className="text-row">
                  <img className="vaginal" src={waitVaginalBaby} alt="vaginal pic"/>
                  <p>{lexicon.get("76")}</p>
                </div>
                { show24Text &&
                  <div className="text-row">
                    <img className="csection" src={cSectionBaby} alt="csection pic"/>
                    <p>{lexicon.get("24")}</p>
                  </div>
                }
              </div>
              :
              <button className="show-results" onClick={() => showResults(setWaitResultsOpen, 1000, setShow24Text)}>{lexicon.get("show-results")}</button>
            }
          </div>
          { waitWall() }
        </div>
        <div className="schedule section">
          <div className="section-header">
            <div className="hundred-text">
              <p className="hundred">{lexicon.get("100-schedulers-1")}</p>
              <p className="hundred">{lexicon.get("100-schedulers-2")}</p>
            </div>
            {isScheduledResultsOpen 
              ?
              <div className="result-text">
                <div className="text-row">
                  <img className="vaginal" src={waitVaginalBaby} alt="vaginal pic"/>
                  <p>{lexicon.get("80")}</p>
                </div>
                { show20Text &&
                  <div className="text-row">
                    <img className="csection" src={cSectionBaby} alt="csection pic"/>
                    <p>{lexicon.get("20")}</p>
                  </div>
                }
              </div>
              :
              <button className="show-results" onClick={() => showResults(setScheduledResultsOpen, 1000, setShow20Text)}>{lexicon.get("show-results")}</button>
            }
          </div>
          { scheduleWall() }
        </div>
      </div>
        <div className="sources">
          <a target="_blank" href={lexicon.get("sources-link")}>{lexicon.get("sources")}</a>
        </div>
      <BackNext backPath="/proscons" nextPath="/highbp" otherNextButton={nextButton} nextClass="hbp" studyId={studyId}/>
    </div>
  );
}

export default CSection ;
