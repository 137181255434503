import React from 'react';
import './ValuesQ1.scss';
import ValuesQuestion from '../shared/ValuesQuestion.jsx'
const locator = require('./VC_locator.png')

const definitelyPic = require('./Def_purple.png')
const probablyPic = require('./Prob_purple.png')
const doesntMatterPic = require('./DsntMatter_purple.png')
const probablyNotPic = require('./ProbNot_purple.png')
const definitelyNotPic = require('./DefNot_purple.png')

const definitelyDesktopPic = require('./desktop/Def_purple.png')
const probablyDesktopPic = require('./desktop/Prob_purple.png')
const doesntMatterDesktopPic = require('./desktop/DsntMatter_purple.png')
const probablyNotDesktopPic = require('./desktop/ProbNot_purple.png')
const definitelyNotDesktopPic = require('./desktop/DefNot_purple.png')

const ValuesQ1 = ({ answer, setAnswer, lexicon, answersLexicon, studyId }) => {

  return (
    <div id="ValuesQ1">
        <ValuesQuestion 
          question="q1"
          answer={answer} 
          setAnswer={setAnswer} 
          lexicon={lexicon} 
          answersLexicon={answersLexicon} 
          definitelyPic={definitelyPic} 
          probablyPic={probablyPic} 
          doesntMatterPic={doesntMatterPic} 
          probablyNotPic={probablyNotPic} 
          definitelyNotPic={definitelyNotPic} 
          definitelyDesktopPic={definitelyDesktopPic} 
          probablyDesktopPic={probablyDesktopPic} 
          doesntMatterDesktopPic={doesntMatterDesktopPic} 
          probablyNotDesktopPic={probablyNotDesktopPic} 
          definitelyNotDesktopPic={definitelyNotDesktopPic} 
          backPath="/values-start"
          nextPath="/values-q2"
          locator={locator}
          studyId={studyId}
        />
    </div>
  );
}

export default ValuesQ1 ;
