import React from 'react';
import './ValuesQuestion.scss';
import BackNext from './BackNext.jsx'
import MainNav from './MainNav.jsx'
import ValuesAnswers from './ValuesAnswers.jsx'

const ValuesQuestion = ({ 
                           question,
                           answer, 
                           setAnswer, 
                           lexicon, 
                           answersLexicon ,
                           definitelyPic,
                           definitelyNotPic,
                           probablyPic,
                           probablyNotPic,
                           doesntMatterPic,
                           definitelyDesktopPic,
                           definitelyNotDesktopPic,
                           probablyDesktopPic,
                           probablyNotDesktopPic,
                           doesntMatterDesktopPic,
                           backPath,
                           nextPath,
                           locator,
                           studyId
                        }) => {

  return (
    <div id="ValuesQuestion">
      <div className="top">
        <MainNav current="/summary" studyId={studyId} />
        <img className="locator" src={locator} alt="Locator"></img>
        <div className="headers">
          <h1>{lexicon.get("title")}</h1>
        </div>
        <ValuesAnswers 
          answer={answer} 
          setAnswer={setAnswer} 
          definitelyPic={definitelyPic} 
          probablyPic={probablyPic} 
          doesntMatterPic={doesntMatterPic} 
          probablyNotPic={probablyNotPic} 
          definitelyNotPic={definitelyNotPic} 
          definitelyDesktopPic={definitelyDesktopPic} 
          probablyDesktopPic={probablyDesktopPic} 
          doesntMatterDesktopPic={doesntMatterDesktopPic} 
          probablyNotDesktopPic={probablyNotDesktopPic} 
          definitelyNotDesktopPic={definitelyNotDesktopPic} 
          question={question}
          lexicon={answersLexicon} 
        />
      </div>
      <div className="bottom">
        <BackNext backPath={backPath} nextPath={nextPath} studyId={studyId} />
      </div>
    </div>
  );
}

export default ValuesQuestion ;
