import React from 'react';
import './ValuesQ4.scss';
import ValuesQuestion from '../shared/ValuesQuestion.jsx'
const locator = require('./Locator_3.png')

const definitelyPic = require('./Def_green.png')
const probablyPic = require('./Prob_green.png')
const doesntMatterPic = require('./DsntMatter_green.png')
const probablyNotPic = require('./ProbNot_green.png')
const definitelyNotPic = require('./DefNot_green.png')

const definitelyDesktopPic = require('./desktop/Def_green.png')
const probablyDesktopPic = require('./desktop/Prob_green.png')
const doesntMatterDesktopPic = require('./desktop/DsntMatter_green.png')
const probablyNotDesktopPic = require('./desktop/ProbNot_green.png')
const definitelyNotDesktopPic = require('./desktop/DefNot_green.png')

const ValuesQ4 = ({ answer, setAnswer, lexicon, answersLexicon, studyId }) => {

  return (
    <div id="ValuesQ4">
        <ValuesQuestion 
          question="q4"
          answer={answer} 
          setAnswer={setAnswer} 
          lexicon={lexicon} 
          answersLexicon={answersLexicon} 
          definitelyPic={definitelyPic} 
          probablyPic={probablyPic} 
          doesntMatterPic={doesntMatterPic} 
          probablyNotPic={probablyNotPic} 
          definitelyNotPic={definitelyNotPic} 
          definitelyDesktopPic={definitelyDesktopPic} 
          probablyDesktopPic={probablyDesktopPic} 
          doesntMatterDesktopPic={doesntMatterDesktopPic} 
          probablyNotDesktopPic={probablyNotDesktopPic} 
          definitelyNotDesktopPic={definitelyNotDesktopPic} 
          backPath="/values-q3"
          nextPath="/values-q5"
          locator={locator}
          studyId={studyId}
        />
    </div>
  );
}

export default ValuesQ4 ;
