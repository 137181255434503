import React from 'react';
import './QuestionNav.scss';
import { Link } from 'react-router-dom';

const QuestionNav = ({ current, lexicon, studyId }) => {

  return (
    <div id="QuestionNav">
      <Link to={"/q1/" + studyId}>
        <div className={"item q1" + (current === "q1" ? " selected" : "")} >
          <span>{lexicon.get("Q1.title")}</span>
        </div>
      </Link>
      <Link to={"/q2/" + studyId}>
        <div className={"item q2" + (current === "q2" ? " selected" : "")} >
          <span>{lexicon.get("Q2.title")}</span>
        </div>
      </Link>
      <Link to={"/q3/" + studyId}>
        <div className={"item q3" + (current === "q3" ? " selected" : "")} >
          <span>{lexicon.get("Q3.title")}</span>
        </div>
      </Link>
      <Link to={"/q4/" + studyId}>
        <div className={"item q4" + (current === "q4" ? " selected" : "")} >
          <span>{lexicon.get("Q4.title")}</span>
        </div>
      </Link>
      <Link to={"/q5/" + studyId}>
        <div className={"item q5" + (current === "q5" ? " selected" : "")} >
          <span>{lexicon.get("Q5.title")}</span>
        </div>
      </Link>
      <Link to={"/q6/" + studyId}>
        <div className={"item q6" + (current === "q6" ? " selected" : "")} >
          <span>{lexicon.get("Q6.title")}</span>
        </div>
      </Link>
    </div>
  );
}

export default QuestionNav ;
