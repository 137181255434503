import React from 'react';
import { Link } from 'react-router-dom';
import './Welcome.scss';
import Markdown from '../Markdown'
import BackNext from '../shared/BackNext.jsx'
const womanOnChair = require('./woman_on_chair.png')
const elWoman = require('./el_woman.png')
const nextButton = require('../shared/Next.png')

const Welcome = ({ lexicon, studyId }) => {

  return (
    <div id="Welcome">
      <div className="intro">
        <h1>{lexicon.get("welcome")}</h1>
        <h2>{lexicon.get("congrats")}</h2>
        <div className="content">
          <Markdown str={lexicon.get("think-about")} />
          <Markdown str={lexicon.get("two-options")} />
        </div>
      </div>
      <div className="wait-or-schedule">
        <div className="wait">
          <h3>{lexicon.get('wait')}</h3>
          <div className="bullet-content">
            <img src={womanOnChair} className="woman-on-chair" alt="Woman on chair pic" />
            <ul>
              <li>
                <div className="bullet-text">
                  <Markdown str={lexicon.get("wait-text")} />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="or">
          <p>{lexicon.get('or')}</p>
        </div>
        <div className="schedule">
          <h3>{lexicon.get('schedule')}</h3>
          <div className="bullet-content">
            <img src={elWoman} className="el-woman" alt="Elective Induction woman pic" />
            <ul>
              <li>
                <div className="bullet-text">
                  <Markdown str={lexicon.get("39-weeks")} />
                </div>
              </li>
              <li>
                <div className="bullet-text">
                  <Markdown str={lexicon.get("an-induction")} />
                </div>
              </li>
              <li>
                <div className="bullet-text">
                  <Markdown str={lexicon.get("an-elective")} />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="bubble">
          <div className="bubble-text">
            <Markdown str={lexicon.get("your-health")} />
          </div>
        </div>
        <div className="sources">
          <a target="_blank" href={lexicon.get("sources-link")}><Markdown str={lexicon.get("sources")} /></a>
        </div>
        <BackNext 
          backPath="/" 
          nextPath="/about" 
          otherNextButton={nextButton} 
          studyId={studyId} 
        />
      </div>
    </div>
  );
}

export default Welcome ;
