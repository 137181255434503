import React from 'react';
import './Q6.scss';
import Markdown from '../Markdown'
import CalendarBullets from '../shared/CalendarBullets.jsx'
import BackNext from '../shared/BackNext.jsx'
import QuestionNav from '../shared/QuestionNav.jsx'
import MainNav from '../shared/MainNav.jsx'
import BothCalendars from '../shared/BothCalendars.jsx'
const nextButton = require('./ProsCons.png')
const figure = require('./Q6_figure.png')

const Q6 = ({ lexicon, studyId }) => {

  return (
    <div id="Q6">
      <div className="top">
        <div className="left">
          <div className="navs">
            <MainNav current="/qanda" studyId={studyId} />
            <QuestionNav lexicon={lexicon} current="q6" studyId={studyId} />
          </div>
          <div className="headers">
            <h1>{lexicon.get("Q6.title")}</h1>
          </div>
        </div>
        <div className="right desktop">
          <img className="figure" src={figure} alt="Pregnant pic"/>
        </div>
      </div>
      <div className="calendars">
        <div className="mobile">
          <div className="calendar-container">
            <CalendarBullets 
              title={lexicon.get("Q6.same-mobile")}
              bullets={[lexicon.get("Q6.goal"),
                        lexicon.get("Q6.however"),
                        lexicon.get("Q6.cesarean")
                      ]} 
              calendarType="both"
            />
        </div>
          <BackNext backPath="/q5" nextPath="/proscons" otherNextButton={nextButton} studyId={studyId} />
        </div>
        <div className="desktop">
          <div className="bottom">
            <div className="wait">
            </div>
            <div className="schedule">
            </div>
            <div className="content">
              <div className="row">
                <BothCalendars />
                <h3><Markdown str={lexicon.get("Q6.same-desktop")} /></h3>
              </div>
              <ul>
                <li>{lexicon.get("Q6.goal")}</li>
                <li>
                  <Markdown str={lexicon.get("Q6.however")} />
                </li>
                <li>{lexicon.get("Q6.cesarean")}</li>
              </ul>
              <div className="back-next-desktop">
                <BackNext backPath="/q5" nextPath="/proscons" otherNextButton={nextButton} studyId={studyId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q6 ;
