import React from 'react';
import Markdown from '../Markdown'
import './CalendarBullets.scss';
const elCalendar = require('./el_calendar.png');
const waitCalendar = require('./wait_calendar.png');
const bothElCalendar = require('./both_el_calendar.png');
const bothWaitCalendar = require('./both_wait_calendar.png');

const CalendarBullets = ({ title, bullets, calendarType }) => {

  const items = bullets.map((bulletText, i) => {
    return (
      <li key={i}>
        <div className="bullet-text">
          <Markdown str={bulletText} />
        </div>
      </li>
    )
  })

  var both = false;
  var calendarPic = null;
  if (calendarType === 'schedule' ) {
    calendarPic = elCalendar
  } else if (calendarType === 'wait' ) {
    calendarPic = waitCalendar
  } else {
    both = true;
  }

  return (
    <div id="CalendarBullets">
      <div className={`${calendarType}`}>
        <div className="with-calendar">
          <h3>{title}</h3>
          { both ? 
            <div className="both-calendar">
              <img className="wait" src={bothWaitCalendar} alt="Wait calendar pic" />
              <img className="schedule" src={bothElCalendar} alt="Schedule calendar pic" />
            </div>
          :
            <img src={calendarPic} alt="Calendar pic" />
        }
        </div>
          <ul>
            {items}
          </ul>
      </div>
    </div>
  );
}

export default CalendarBullets ;
