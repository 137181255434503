import React from 'react';
import './Q2.scss';
import CalendarBullets from '../shared/CalendarBullets.jsx'
import BackNext from '../shared/BackNext.jsx'
import MainNav from '../shared/MainNav.jsx'
import QuestionNav from '../shared/QuestionNav.jsx'
import Markdown from '../Markdown'
const figure = require('./Q2_figure.png')

const Q2 = ({ lexicon, studyId }) => {

  return (
    <div id="Q2">
      <div className="top">
        <div className="left">
          <div className="navs">
            <MainNav current="/qanda" studyId={studyId} />
            <QuestionNav lexicon={lexicon} current="q2"  studyId={studyId}/>
          </div>
          <div className="headers">
            <h1>{lexicon.get("Q2.title")}</h1>
          </div>
        </div>
        <img className="desktop" src={figure} alt="Pregnant pic"/>
      </div>
      <div className="bottom">
        <div className="calendars">
          <CalendarBullets 
            title={lexicon.get("Q2.wait")}
            bullets={[lexicon.get("Q2.when"),
                      lexicon.get("Q2.monitor"),
                      lexicon.get("Q2.as-hoped")    
                    ]} 
            calendarType="wait"
          />
          <CalendarBullets 
            title={lexicon.get("Q2.schedule")}
            bullets={[lexicon.get("Q2.schedule-day"),
                      lexicon.get("Q2.cervix"),   
                      lexicon.get("Q2.pitocin-1"),    
                      lexicon.get("Q2.pitocin-2"),   
                      lexicon.get("Q2.pitocin-3")    
                    ]} 
            calendarType="schedule"
          />
          <div className="mobile">
            <div className="sources">
              <a target="_blank" href={lexicon.get("Q2.more-information-link")}><Markdown str={lexicon.get("Q2.more-information")} /></a>
            </div>
            <BackNext backPath="/q1" nextPath="/q3" studyId={studyId}/>
          </div>
        </div>
        <div className="sources desktop">
          <a target="_blank" href={lexicon.get("Q2.more-information-link")}><Markdown str={lexicon.get("Q2.more-information")} /></a>
        </div>
        <div className="back-next-desktop">
          <BackNext backPath="/q1" nextPath="/q3" studyId={studyId}/>
        </div>
      </div>
    </div>
  );
}

export default Q2 ;
