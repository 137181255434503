import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { EditWrapper, Lexicon } from '@nitidbit/lexicon';
import rawLexicon from './ElectiveInductionStrings.json';
import history from '../history';
import Study from './Study/Study.jsx'
import Welcome from './Welcome/Welcome.jsx'
import About from './About/About.jsx'
import Q1 from './Q1/Q1.jsx'
import Q2 from './Q2/Q2.jsx'
import Q3 from './Q3/Q3.jsx'
import Q4 from './Q4/Q4.jsx'
import Q5 from './Q5/Q5.jsx'
import Q6 from './Q6/Q6.jsx'
import Background from './shared/Background.jsx'
import ScrollToTop from './shared/ScrollToTop.jsx'
import ProsCons from './ProsCons/ProsCons.jsx'
import CSection from './CSection/CSection.jsx'
import HighBP from './HighBP/HighBP.jsx'
import Stories from './Stories/Stories.jsx'
import Values from './Values/Values.jsx'
import ValuesQ1 from './ValuesQ1/ValuesQ1.jsx'
import ValuesQ2 from './ValuesQ2/ValuesQ2.jsx'
import ValuesQ3 from './ValuesQ3/ValuesQ3.jsx'
import ValuesQ4 from './ValuesQ4/ValuesQ4.jsx'
import ValuesQ5 from './ValuesQ5/ValuesQ5.jsx'
import ValuesQ6 from './ValuesQ6/ValuesQ6.jsx'
import Summary from './Summary/Summary.jsx'
import './reset.scss';
import './App.scss';

const REACT_APP_LEXICON_UPDATE_URL="https://lexicon.nitid.co/update"

const App = ({ lexicon }) => {

  const storedAnswer = (qNum) => {
    const answer = localStorage.getItem(qNum);
    //console.log(`Getting answer ${answer} from localStorage for: ${qNum}`)
    return (answer === "null" ? "" : answer);
  }

  const [q1Answer, setQ1Answer] = useState(storedAnswer("q1"));
  const [q2Answer, setQ2Answer] = useState(storedAnswer("q2"));
  const [q3Answer, setQ3Answer] = useState(storedAnswer("q3"));
  const [q4Answer, setQ4Answer] = useState(storedAnswer("q4"));
  const [q5Answer, setQ5Answer] = useState(storedAnswer("q5"));
  const [q6Answer, setQ6Answer] = useState(storedAnswer("q6"));
  const [studyId, setStudyId] = useState("")

  useEffect(
    () => {
      //console.log("Storing answers")
      localStorage.setItem("q1", q1Answer);
      localStorage.setItem("q2", q2Answer);
      localStorage.setItem("q3", q3Answer);
      localStorage.setItem("q4", q4Answer);
      localStorage.setItem("q5", q5Answer);
      localStorage.setItem("q6", q6Answer);
    },
    [q1Answer, q2Answer, q3Answer, q4Answer, q5Answer, q6Answer]
  );

  return (
    <div id="App">
      <Background>
      <Router history={history}>
        <div>
        <ScrollToTop history={history} />
        <Switch>
          <Route path="/" exact>
            <Study 
              lexicon={lexicon.subset('Study')} 
              studyId={studyId} 
              setStudyId={setStudyId}
            />
          </Route>
          <Route path="/welcome" >
            <Welcome 
              lexicon={lexicon.subset('Welcome')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/about">
            <About 
              lexicon={lexicon.subset('About')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q1" >
            <Q1 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q2" >
            <Q2 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q3" >
            <Q3 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q4" >
            <Q4 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q5" >
            <Q5 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/q6" >
            <Q6 
              lexicon={lexicon} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/proscons" >
            <ProsCons 
              lexicon={lexicon.subset('ProsCons')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/csection" >
            <CSection 
              lexicon={lexicon.subset('CSection')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/highbp" >
            <HighBP 
              lexicon={lexicon.subset('HighBP')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/stories" >
            <Stories 
              lexicon={lexicon.subset('Stories')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/questionnaire" >
            <Values 
              lexicon={lexicon.subset('Values')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-start" >
            <Values 
              lexicon={lexicon.subset('Values')} 
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q1" >
            <ValuesQ1 
              answer={q1Answer}
              setAnswer={setQ1Answer}
              lexicon={lexicon.subset('ValuesQ1')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q2" >
            <ValuesQ2 
              answer={q2Answer}
              setAnswer={setQ2Answer}
              lexicon={lexicon.subset('ValuesQ2')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q3" >
            <ValuesQ3 
              answer={q3Answer}
              setAnswer={setQ3Answer}
              lexicon={lexicon.subset('ValuesQ3')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q4" >
            <ValuesQ4 
              answer={q4Answer}
              setAnswer={setQ4Answer}
              lexicon={lexicon.subset('ValuesQ4')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q5" >
            <ValuesQ5 
              answer={q5Answer}
              setAnswer={setQ5Answer}
              lexicon={lexicon.subset('ValuesQ5')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/values-q6" >
            <ValuesQ6 
              answer={q6Answer}
              setAnswer={setQ6Answer}
              lexicon={lexicon.subset('ValuesQ6')} 
              answersLexicon={lexicon.subset('ValuesAnswers')}
              studyId={studyId} 
            />
          </Route>
          <Route path="/summary" >
            <Summary 
              answer1={q1Answer}
              answer2={q2Answer}
              answer3={q3Answer}
              answer4={q4Answer}
              answer5={q5Answer}
              answer6={q6Answer}
              lexicon={lexicon.subset('Summary')} 
              studyId={studyId} 
            />
          </Route>
        </Switch>
        </div>
      </Router>
      </Background>
    </div>
  );
}

const electiveInductionLexicon = new Lexicon(rawLexicon, 'en', 'src/components/ElectiveInductionStrings.json');

const EditableElectiveInduction = () => (
  <EditWrapper
    apiUpdateUrl={REACT_APP_LEXICON_UPDATE_URL}
    lexicon={electiveInductionLexicon}
    component={props => (
      <App
        lexicon={props.lexicon}
      />
    )}
  />
);


const NonEditableElectiveInduction = () => (
  <App
    lexicon={electiveInductionLexicon}
  />
)

export default EditableElectiveInduction ;
