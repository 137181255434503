import React from 'react';
import './Popup.scss';
const closeButton = require('../shared/close.png');


const Popup = ({ setPopupOpen, content}) => {

  return (
    <div id="Popup">
      <button className="close" onClick={() => setPopupOpen(false)}>
        <img src={closeButton} alt="close button" />
      </button>
      {content}
    </div>
  );
}

export default Popup ;
