import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './fonts/kefa-ii-pro-regular.woff'
import './fonts/kefa-ii-pro-bold.woff'
import './index.css'

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'G-TN1JTWN4RR'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
